* {
    box-sizing: border-box;
}

body,
html,
#root {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: #272727;
}

img {
    max-width: 100%;
}

.full-height {
    height: 100vh;
}

.min-height {
    min-height: calc(100vh - 105px);
}

.inner-content-bottom {
    padding-bottom: 200px;
}

.registeration-page {
    background-color: #000000;
    padding: 30px 0;
    color: #fff;
    font-family: 'Poppins', sans-serif;
}

.centered-box {
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    max-width: 540px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.logo {

    width: 100%;

    display: block;
    margin: 0 auto;
}

.login-page h2 {
    font-size: 42px;
    text-align: center;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 5px;
    border-bottom: 2px solid #c61c21;
    line-height: 64px;
    display: inline-block;
}

.form-block .input-control {
    border: 1px solid #d4d4d4;
    width: 100%;
    padding: 5px 14px;
    display: block;
    background: #1d1d1d;
    letter-spacing: 0.4px;
    font-weight: lighter;
    outline: 0;
    margin-bottom: 35px;
    border-radius: 10px;
    min-height: 60px;
    color: #fff;
}

.login-page .center-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

::placeholder {
    color: #fff;
    opacity: 0.9;
}

:-ms-input-placeholder {
    color: #fff;
    opacity: 0.9;
}

::-ms-input-placeholder {
    color: #fff;
    opacity: 0.9;
}

.imursif-link {
    text-decoration: none;
    color: #3b2c86;
    text-align: center;
}

.p-head {
    font-size: 20px;
    padding-left: 10px;
    margin-bottom: 35px;
    margin-top: 0px;
}

.bottom-text {
    text-align: center;
    margin: 0px 0px 20px 0px;
}

.btn-save {
    color: #fff;
    border-radius: 13px;
    background: #3b2c86;
    padding: 10px;
    width: 100%;
    height: 60px;
    font-size: 17px;
    margin-top: 56px;
}

.btn-save:hover {
    background: rgb(34, 35, 35) !important;
    box-shadow: 0px 1px 5px 2px #3b2c86 !important;
}

@media(max-width:991px) {
    .logo {
        max-width: 200px;
    }

    .registeration-page h2 {
        font-size: 35px;
    }

    .p-head {
        font-size: 18px;
    }

    .form-block {
        max-width: 100%;
        margin-left: 0px;
    }

    .center-block {
        min-height: auto;
    }
}






.registeration-page {
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    color: #fff;
    padding: 30px 0;
}

.centeredbox {
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    max-width: 560px;
    width: 100%;
    padding-left: 15px;
    text-align: center;
    padding-right: 15px;
}

.logo-img {
    height: 78px;
    object-fit: contain;
    margin: 0 auto;
    display: block;
}

.red-color {
    color: #c61c21;
}

.centeredbox h2 {
    margin: 13px 0px 20px 0px;
    font-size: 48px;
}

.textgrey {
    margin: 0px 0px 36px 0px;
    color: #dfdfdf;
    font-size: 21px;
}

.img-download {
    width: 200px;
    /*border: 1px solid #3a3c3a;*/
    border-radius: 13px;
}

.btn-link {
    display: flex;
    justify-content: center;
    align-items: center;

}

@media(max-width: 767px) {
    .registeration-page {

    }

    .centeredbox h2 {
        font-size: 30px;
        margin: 17px 0px 20px 0px;
    }

    .textgrey {
        font-size: 17px;
    }

    .logo-img {
        height: 58px;
    }

    .img-download {
        max-width: 200px;
        width: 100%;
    }

    .min-height {
        height: auto;
    }

    .headeralert {
        font-size: 13px;
    }

}


.main-body {
    display: flex;
    width: 100%;
    height: 100%;
}

.sidebar {
    width: 300px;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #000;
    bottom: 0;
    height: 100%;
    z-index: 99;
}

.main-content {
    margin-left: 300px;
    width: calc(100% - 300px);
}

.side-navbar {
    list-style: none;
    padding-left: 0;
}

.logoimg {
    margin-top: 10px;
    max-width: 160px;
}

.side-navbar {
    margin-top: 40px;
    padding-left: 15px;
    padding-right: 15px;
}

.side-navbar li a {
    display: inline-block;
    width: 100%;
    padding: 16px 16px;
    border-radius: 6px;
    font-weight: 500;
    color: #aaa;
    margin-bottom: 15px;
    text-decoration: none;
    display: flex;
    align-items: flex-end;
    position: relative;
    transition: 0.5s all;
}

.side-navbar li img {
    filter: grayscale(1);
    margin-right: 11px;
    position: relative;
    top: -3px;
    transition: 0.5s all;
}

.side-navbar li a:hover span {
    color: #fff;
    opacity: 0.8;
}

.side-navbar li a:hover img {
    filter: brightness(0) invert(0.8);
}

.side-navbar .active img {
    filter: none !important;
}


.side-navbar li.active a {
    background: #3635358f !important;
    color: #fff !important;
}

.text-white {
    color: #fff;
}

.demo-pic {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
}

.box-wiget {
    background: #464343b3;
    padding: 30px;
    border-radius: 35px;
}

.profile-page {
    background: #000;
    min-height: 100vh;
    color: #fff;
    padding: 50px 0;
    font-family: 'Poppins', sans-serif;
}

.authlogo {
    width: 247px;
    margin-bottom: 40px;
}

.form-block .input-control {
    border: 1px solid #313030;
    width: 100%;
    padding: 5px 14px;
    display: block;
    background: #1d1d1d;
    letter-spacing: 0.4px;
    font-weight: lighter;
    outline: 0;
    margin-bottom: 15px;
    border-radius: 10px;
    min-height: 51px;
    color: #fff;
}





p.headtitle {
    font-size: 18px;
    color: #5d46d2;
    margin-bottom: 5px;
    font-weight: 600;
}

p.sub {
    color: #fff;
}

.text-link {
    color: #fff;
    cursor: pointer;
    transition: 0.4s all;
}

.text-link:hover {
    color: #3b2c86;
    text-decoration: none;
}



.no-video h2 {
    position: relative;
    margin-bottom: 20px;
    padding-bottom: 10px;
}

.no-video h2:after {
    content: '';
    background: #7b7b7b;
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0px;
    right: 0;
}

.logo-image-profile {
    text-align: center;
}

.logo-image-profile img {
    max-width: 220px;
    width: 100%;
    padding-bottom: 30px;
}

.scroll-iframe body {
    overflow-y: hidden !important;
}


@media(min-width:567px) {
    .no-video {
        height: calc(100vh - 240px);
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        max-width: 450px;
        margin: 0 auto;
        text-align: left;
    }

}

@media(max-width:566px) {
    .no-video {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        max-width: 450px;
        margin: 35px auto;
        text-align: left;
    }

    .no-video h4 {
        font-size: 18px;
    }

    .headeralert {
        font-size: 10px;
    }

    .no-video h3 {
        font-size: 22px;
    }



}


.navbar-new {
    background: #000;
}





p.headtitle {
    font-size: 18px;
    color: #5d46d2;
    margin-bottom: 5px;
    font-weight: 600;
}

p.sub {
    color: #fff;
}

.app {
    margin-top: 60px;
}

.text-link {
    color: #fff;
    cursor: pointer;
    transition: 0.4s all;
}

.text-link:hover {
    color: #3b2c86;
    text-decoration: none;
}



.download-app img {
    width: 65px;
    height: 70px;
    object-fit: contain;
}









.form-block .input-control {
    border: 1px solid #313030;
    width: 100%;
    padding: 5px 14px;
    display: block;
    background: #1d1d1d;
    letter-spacing: 0.4px;
    font-weight: lighter;
    outline: 0;
    margin-bottom: 15px;
    border-radius: 10px;
    min-height: 51px;
    color: #fff;
}

.profile-page .imursif-link {
    font-size: 48px;
}



p.headtitle {
    font-size: 18px;
    color: #5d46d2;
    margin-bottom: 5px;
    font-weight: 600;
}

p.sub {
    color: #fff;
}


.centered-box {
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    max-width: 540px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.logo {
    max-width: 280px;
    width: 100%;
}

.registeration-page h2 {
    font-size: 42px;
    text-align: center;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 5px;
}

.form-block .input-control {
    border: 1px solid #d4d4d4;
    width: 100%;
    padding: 5px 14px;
    display: block;
    background: #1d1d1d;
    letter-spacing: 0.4px;
    font-weight: lighter;
    outline: 0;
    margin-bottom: 18px;
    border-radius: 10px;
    min-height: 60px;
    color: #fff;
}

.form-block input:-webkit-autofill,
.form-block input:-webkit-autofill:hover,
.form-block input:-webkit-autofill:focus {
    border: 1px solid #fff;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

.register-page .center-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.p-head {
    font-size: 20px;
    padding-left: 10px;
    margin-bottom: 35px;
    margin-top: 0px;
}

.bottom-text {
    text-align: center;
    margin: 0px 0px 20px 0px;
}



.form-block {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.register-page .form-block {
    max-width: 410px !important;
    margin-left: 50px;
}

.login-page .center-block {
    min-height: unset;
}

.login-page .form-block {
    max-width: 520px;
}

/* thankyou */
.thankyou-stroke {
    text-align: center;
    font-size: 80px;
    -webkit-text-stroke: 1px #909090;
    -webkit-text-fill-color: #c61c21;
    font-weight: 700;
}

@media(max-width:991px) {
    .logo {
        max-width: 200px;
    }

    .registeration-page h2 {
        font-size: 35px;
    }

    .p-head {
        font-size: 18px;
    }

    .form-block {
        max-width: 100% !important;
        margin-left: 0px !important;
    }

    .center-block {

    }
}





/*subscription new*/
.sidebar {
    z-index: 99;
}

.f-18 {
    font-size: 18px;
}

.subscript-width {
    max-width: 500px !important;
}

.active-timing {
    min-height: 48px;
}

.subscription-box {
    background-color: #1A1A1A;
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 35px 30px 20px 30px;
    width: 100%;
    position: relative;

}

.subscription-info {
    min-height: 130px;
    padding-bottom: 5px;
}

.right-subs {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 20px;
}

.btn-buy {
    background-color: #C61C21;
    border: 1px solid #4f4c4c;
    padding: 14px 10px;
    color: #e5e5e5;
    width: 100%;
    font-size: 18px;
    cursor: pointer;
}

.imursif-img {
    max-width: 210px;
}

.text-red {
    color: #C61C21;
}

.subscription-box h2 {
    font-size: 30px;
    font-weight: 600;
    color: #fff;
    margin-top: 10px;
}


.link {
    color: #5d46d2;
}

.btn-new-red,
.btn-new-red:hover,
.btn-new-red:focus {
    background-color: #3b2c86 !important;
    color: #fff !important;
}

.btn-new-red:hover {
    background: rgb(34, 35, 35) !important;
    box-shadow: 0px 1px 5px 2px #3b2c86 !important;
}

.check-list li {
    color: #fff;
    margin-bottom: 20px;
    font-size: 16px;
    position: relative;
    padding-left: 28px;
}

.check-list li:before {
    content: '';
    width: 16px;
    height: 16px;
    background-image: url(../assets/images/check2.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: absolute;
    left: 0px;
    top: 4px;
}

.subscription-box .subscription-price {
    font-size: 38px;
    font-weight: 700;
}

.text-underline {
    text-decoration: underline;
    font-size: 20px;
}

.active-badge {
    margin: 0px;
    background: #00B21E;
    display: inline-block;
    position: absolute;
    color: #fff;
    margin: 0 auto;
    width: auto;
    left: calc(50% - 40px);
    top: -16px;
    font-size: 16px;
    padding: 3px 19px;
    border-radius: 16px;
}

.center-stroke {
    position: relative;
}

/* .center-stroke:after {
			content: '';
			position: absolute;
			left: 0;
			top: 15%;
			right: 0;
			background-color: #fff;
			margin: 0 auto;
			width: 1px;
			height: 85%;
		} */

@media(max-width: 991px) {
    .header-fix {



    }

    .main-content {
        margin-left: 0px;
        width: 100%;
    }

    .sidebar {
        display: none;
    }

    .subscription-box {
        padding: 35px 20px 20px 20px;
    }

    .check-list li:before {
        width: 18px;
        height: 18px;
    }

    .check-list li {
        padding-left: 25px;
    }

    .subscription-box h2 {
        font-size: 24px;
    }

}





.registeration-page,
.thankyou-app {
    background-color: #000000;
    display: block;

    color: #fff;
    font-family: 'Poppins', sans-serif;
}

.centeredbox {
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    max-width: 560px;
    width: 100%;
    padding-left: 15px;
    text-align: center;
    padding-right: 15px;
}

.logo-img {
    height: 150px;
    object-fit: contain;
    margin: 0 auto;
    display: block;
    margin-bottom: 40px;
}

.red-color {
    color: #c61c21;
}

.centeredbox h2 {
    margin: 13px 0px 20px 0px;
    font-size: 48px;
}

.textgrey {
    margin: 0px 0px 36px 0px;
    color: #dfdfdf;
    font-size: 21px;
}

.img-download {
    width: 200px;
    /*border: 1px solid #3a3c3a;*/
    border-radius: 13px;
}

.btn-link {
    display: flex;
    justify-content: center;
    align-items: center;

}

.registeration-page .error-p {
    top: -10px;
    position: relative;
}

@media(max-width: 767px) {
    .registeration-page {

    }

    .thankyou-stroke {
        font-size: 46px;
    }

    .centeredbox h2 {
        font-size: 30px;
        margin: 17px 0px 20px 0px;
    }

    .textgrey {
        font-size: 17px;
    }

    .logo-img {
        height: 58px;
    }

    .img-download {
        max-width: 200px;
        width: 100%;
    }

    .active-timing {
        min-height: auto;
    }
}

.error-p {
    text-align: left;
    color: #f00;
    font-size: 14px;
}

.form-block .input-control.invalid-input {
    border-color: #f00;
    box-shadow: inset 0px 0px 4px #f00;
}

.block-class {
    display: block;
}





.list {
    width: 100%;
    display: flex;
    overflow-x: auto;
}

.list.draggingOver {
    background-color: rgba(64, 66, 67, 0.066);
}

/* .item {
  user-select: none;
  padding: 10px;
  margin: 0;
  margin-bottom: 8px;
  background-color: magenta;
  display: inline-block;
}

.item.dragging {
  background-color: pink;
} */