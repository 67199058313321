.wrapper {
  height: calc(100vh - 110px);
}

body {
  overflow: hidden;
}

.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: 0 auto;
}

.header {
  flex: 0 0 auto;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.content .videoDetails {
  width: 100%;

}

.lock {

  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: #fff;
  color: #c30000;
  width: 25px;
  height: 25px;
  font-size: 16px;
  align-content: center;
  top: 5px;
  right: 5px;


  border-radius: 50%;

  z-index: 9;
}

.dashboard-instructions {
  width: 100%
}

.dashboard-instructions-wrapper {
  display: flex;
  justify-content: center;
  flex-flow: column wrap;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (max-width: 512px) {
  .content {
    flex: 1 1 auto;
    position: relative;/* need this to position inner content */
    overflow-y: auto;

    display: block;
    flex-wrap: nowrap;

  }
  .content .videoDetails {
      padding: 0 20px;

  }

  .dashboard-instructions img {
    width: 250px;
    margin: 20px;
  }

}
@media (min-width: 513px) {

  .imursif {
    padding:0;
    margin-top:-20px
  }
  .content {
    flex: 1 1 auto;
    position: relative; /* need this to position inner content */
    overflow-y: auto;

    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    flex-direction: row;


  }

  .dashboard-instructions img {
    width: 500px;
    margin: 20px 10px;
  }
}

.footer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  position: sticky;


}


.headtitle-3 {
  margin: 0;
  font-size: 24px;
  font-weight: 400 !important;
}

.btn {
  margin-top: 15px;
  margin-bottom: 30px;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
  margin-top: 20px;
}
.react-tabs__tab-list {
  border-bottom: 1px solid #707070 !important;
  margin: 0;
  padding: 0;
}
.react-tabs__tab {
  display: inline-block;
  border: 1px solid #707070 !important;
  border-bottom: 1px solid #707070 !important;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  background: #272727 !important;
  color: white !important;

}
.react-tabs__tab--selected {
  background: #000 !important;
  border-bottom: 1px solid transparent !important;
  border-radius: 5px 5px 0 0;
  border-bottom: none;
}
.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}
.react-tabs__tab:focus {
  outline: none;
}
.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #3b2c86;
}
.react-tabs__tab-panel {
  display: none;
}
.react-tabs__tab-panel--selected {
  display: block;
}

.bottom-products {
  width: -webkit-fill-available !important;
  display: block;
}

.main-body {
  display: flex;
  width: 100%;
  height:100dvh;
}

.sidebar {
  width: 300px;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #000;
  bottom: 0;
  height: 100%;
  z-index: 99;
}

.main-content {
  display: flex;
  flex-direction: column;
  margin-left: 300px;
  width: calc(100% - 300px);

}

.main-content-body {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.side-navbar {
  list-style: none;
  padding-left: 0;
}

.sidelogo {
  padding-left: 30px;
  padding-right: 30px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  text-align: center;
  height: 60px;
  border-bottom: 1px solid rgb(249 250 246 / 20%);
}

.sidelogo .logo {
  max-width: 160px;
}

.side-navbar {
  margin-top: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

.side-navbar li a {
  display: inline-block;
  width: 100%;
  padding: 16px 16px;
  border-radius: 6px;
  font-weight: 500;
  color: #aaa;
  margin-bottom: 15px;
  text-decoration: none;
  display: flex;
  align-items: flex-end;
  position: relative;
  transition: 0.5s all;
}

.side-navbar li img {
  filter: grayscale(1);
  margin-right: 11px;
  position: relative;
  width: 18px;
  top: -3px;
  transition: 0.5s all;
}

.side-navbar li a:hover span {
  color: #fff;
  opacity: 0.8;
}

.side-navbar li a:hover img {
  filter: brightness(0) invert(0.8);
}

.side-navbar .active img {
  filter: none !important;
}

.side-navbar li.active a {
  background: #3635358f !important;
  color: #fff !important;
}

.text-white {
  color: #fff;
}

.header-fix {
  display: flex;
  align-items: center;
  justify-content: flex-end;;
  padding: 10px 25px;
  background: #000;
  height: 60px;
  border-bottom: 1px solid #393939;
}

.trial-fix {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 25px;
  background: #5d46d2;
  height: 60px;
  font-size: 12px;
  border-bottom: 1px solid #393939;
}

.trial-fix .link {
 color: #cbcaca;
}


.well {
  background-color: #1A1A1A;
  border: 1px solid #707070;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  position: relative;
  height: 100%;
}




.demo-pic {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
}

@media (max-width: 992px) {
  .header-fix {
    justify-content: space-between;

  }

  .main-content {
    margin-left: 0px;
    width: 100%;
  }

  .sidebar {
    display: none;
  }
}

* {
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
}

button:focus {
  outline: none;
}

.navbar-new {
  background: #000;
}

.box-wiget {
  background: #464343b3;
  padding: 30px;
  border-radius: 35px;
}

.profile-page {
  background: #000;
  min-height: 100vh;
  color: #fff;
  padding: 50px 0;
  font-family: "Poppins", sans-serif;
}

.authlogo {
  width: 247px;
  margin-bottom: 40px;
}

.form-block .input-control {
  border: 1px solid #313030;
  width: 100%;
  padding: 5px 14px;
  display: block;
  background: #1d1d1d;
  letter-spacing: 0.4px;
  font-weight: lighter;
  outline: 0;
  margin-bottom: 15px;
  border-radius: 10px;
  min-height: 51px;
  color: #fff;
}

::placeholder {
  color: #fff;
  opacity: 0.9;
}

:-ms-input-placeholder {
  color: #fff;
  opacity: 0.9;
}

::-ms-input-placeholder {
  color: #fff;
  opacity: 0.9;
}

.imursif-link {
  text-decoration: none;
  color: #3b2c86;
  text-align: center;
}

.btn-save {
  color: #fff;
  border-radius: 13px;
  background: #3b2c86;
  padding: 10px;
  width: 100%;
  border: none;
  height: 50px;
  font-size: 17px;
}

p.headtitle {
  font-size: 18px;
  color: #5d46d2;
  margin-bottom: 5px;
  font-weight: 600;
}

p.sub {
  color: #fff;
}

.app {
  margin-top: 60px;
}

.text-link {
  color: #fff;
  cursor: pointer;
  transition: 0.4s all;
}

.text-link:hover {
  color: #3b2c86;
  text-decoration: none;
}

.box-widget {
  padding-top: 30px;
}

.app img {
  width: 220px;
  padding-left: 30px;
}

.no-video h2 {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.no-video h2:after {
  content: "";
  background: #7b7b7b;
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0px;
  right: 0;
}

.box-widget {
  text-align: center;
}

.logo-image-profile img {
  width: 280px;
  padding-bottom: 50px;
}

.scroll-iframe body {
  overflow-y: hidden !important;
}

@media (max-width: 767px) {

  html,
  .top-data,
  body,
  #root {
    height: 100%;
    background-color: #272727 !important;
  }
}

@media (min-width: 567px) {
  .no-video {
    height: calc(100vh - 240px);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    max-width: 450px;
    margin: 0 auto;
    text-align: left;
  }

}

@media (max-width: 566px) {
  .no-video {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    max-width: 450px;
    margin: 35px auto;
    text-align: left;
  }

  .no-video h4 {
    font-size: 18px;
  }

  .no-video h3 {
    font-size: 22px;
  }

  .logo-image-profile img {
    width: 210px;
  }

}

/* default css============================================================ */
.body-wrapper {
  padding: 18px 20px;
}

.create-video-btn,
.create-video-btn:focus {
  cursor: pointer;
  border: 1px dashed #fff;
  background: none;
  color: #fff;
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 30px;
  max-width: 500px;
  width: 100%;
  padding: 13px 5px;
  outline: none;
  align-self: center;
  display: grid;
  justify-items: center;
}

.dashboard-videos-grid {
  display: flex;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
}

.dashboard-videos-grid .dashbaord-video {
  width: 250px;
  height: 250px;
  margin: 10px;
  border-radius: 15px;
}

.dashboard-videos-grid .dashbaord-video .thumbimg {
  width: 100%;
  height: 190px;
  object-fit: cover;
  border-radius: 15px 15px 0px 0px;
  background-color: rgb(30, 28, 28);
}

.dashboard-videos-grid .dashbaord-video .upper-dash {
  position: relative;
  height: 190px;
}

.dashboard-videos-grid .dashbaord-video .lower-dash {
  background: #000;
  font-size: 12px;
  padding: 12px 6px;

  border-radius: 0px 0px 15px 15px;
  min-height: 70px;
}

.lower-dash-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.new-video-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: auto;
}


.dashboard-videos-grid .dashbaord-video .lower-dash p {
  margin-bottom: 4px;
}


.video-preview-box .actions,
.dashboard-videos-grid .actions {
  display: flex;
  justify-content: center;
  align-content: space-between;
  position: absolute;
  bottom: -20px;
  width: 100%;
  opacity: 0;
  transition: all .75s ease;

}

.noanimation {
  bottom: 10px !important;
  opacity: 1 !important;
  transition: none !important;

}

@media only screen and (max-width: 768px) {
  .video-preview-box .actions,
  .dashboard-videos-grid .actions {
    bottom: 10px;
    opacity: 1;
  }
}

.video-preview-box .active,
.dashboard-videos-grid .active {
  opacity: 1;
  bottom: 10px;
}


.video-preview-box .actionbtn,
.dashboard-videos-grid .dashbaord-video .actionbtn:first-child {
  margin-left: 0;
}

.video-preview-box .actionbtn,
.dashboard-videos-grid .dashbaord-video .actionbtn {
  margin-left: 10px;
  color: #fff;
  background-color: #3b2c86;
  width: 40px;
  border: none;
  display: flex;
  font-size: 16px;
  align-items: center;
  bottom: 5px;
  right: 5px;
  justify-content: center;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 9;
}


.greyish-text {
  color: #9b9b9b;
}

.no-video-screen img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 140px;
  width: auto;
  object-fit: contain;
}

.no-video-screen {
  min-height: calc(100vh - 180px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0px 20px 0px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}


.product-column-grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
}

.product-column-grid img {
  border-radius: 15px 15px 0px 0px;
  height: 160px;
  width: 100%;
  object-fit: contain;
}

.product-column-grid .product-info {
  background-color: #000;
  color: #fff;
  font-size: 12px;
  padding: 5px;
  text-align: center;
  border-radius: 0px 0px 15px 15px;
}

.select-product {
  min-height: 50px;
  height: 50px;
  border-radius: 8px;
}

.center-wrapper {
  max-width: 1000px;
  position: relative;
  margin: 0 auto;
  width: 100%;
}

.product-column {
  position: relative;
  background: #111010a6;
  border-radius: 15px;
  height: fit-content;
}

.product-column input[type="checkbox"] {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.tick-product {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  border: 1px solid #3b2c86;
  display: none;
}

.tick-product:after {
  position: absolute;
  top: -13px;
  right: -13px;
  content: "";
  background-image: url(./assets/images/check_red.svg);
  background-size: contain;
  background-repeat: no-repeat;
  height: 26px;
  width: 26px;
}

.product-column input[type="checkbox"]:checked+.tick-product {
  display: block;
}

.btn-red-color,
.btn-red-color.btn,
.btn-red-color.btn:first-child {
  background-color: #3b2c86 !important;
  color: #fff !important;
  font-size: 15px;
}

.btn-red-color:hover,
.btn-red-color.btn:hover,
.btn-red-color.btn:first-child:hover {
  background: rgb(34, 35, 35) !important;
  box-shadow: 0px 1px 5px 2px #3b2c86 !important;
}


.btn-block.btn-red-color {
  display: block !important;
}

.max-400 {
  max-width: 400px;
  width: 100%;
}

.max-500 {
  max-width: 500px;
  width: 100%;
}

.radius-8 {
  border-radius: 8px;
}

.bottom-select {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;

}

.scrolling-products {
  min-height: 250px;
  height: calc(100vh - 310px);
  overflow-y: auto;
  padding-right: 15px;
  padding-bottom: 70px;
}

.heading-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}

/* =======Thin scroll==== */
.thin-scroll {
  scrollbar-width: thin;
  overflow-y: auto;
  scrollbar-color: #aaa #fff;
}

.thin-scroll::-webkit-scrollbar {
  width: 5px;
}

.thin-scroll::-webkit-scrollbar {
  height: 10px;
}

.thin-scroll::-webkit-scrollbar-track {
  background: #fff;
}

.thin-scroll::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 20px;
  border: 3px solid #aaa;
}

.edit-add-icon {
  height: 180px;
  margin-bottom: 25px;
}

.selected-video-area {
  max-width: 360px;
  height: 550px;
  margin: 0 auto;
}

.selected-video-area video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.thumb-same {
  min-width: 130px;
  height: 130px;
  border-radius: 8px;
  margin-right: 8px;
  max-width: 170px;
  border: 1px solid white;
  background: #272727;
  /* overflow: hidden; */
}

.add-product-box {
  border: 1px dashed #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.add-product-box img {
  height: 40px;
  margin-bottom: 10px;
}

.product-selected img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: contain;

}

.select-product-slider {
  overflow-x: auto;
  max-width: 100%;

}

.product-selected {
  position: relative;
}

.btn-select-all {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.product-selected .delete-btn {
  top: -10px;
  right: -6px;
  z-index: 9999;
  position: absolute;
}

.product-selected .delete-btn img {
  width: 28px;
}

.input-addproduct {


  width: 100%;
  height: 100%;
  opacity: 0;
}

.btn.disabled,
.btn:disabled,
button.disabled,
button:disabled {
  background-color: #7e7e7e;
  opacity: 0.9;
}

.linkbtn {
  padding: 0px;
  border: none;
}

.bg-none,
.linkbtn {
  background: none;
  background-color: transparent;
}

.video-preview-box {
  border-radius: 15px;
  overflow: hidden;

  width: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.video-preview {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
}

.edit-videoicon {
  position: absolute;
  top: 2px;
  font-size: 22px;
  right: 8px;
  cursor: pointer;
  z-index: 99;
  color: #fff;
  outline: none;
}

.edit-videoicon:focus {
  outline: none;
}

.video-preview-box video,
.video-preview-box img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  background-color: rgb(30, 28, 28);

}

.preview-video-btn,
.preview-video-btn:hover {
  background: #fff;
  display: block;
  padding: 12px 0px;
  text-align: center;
  color: #000;
  text-decoration: none !important;
  align-self: center;
}

.text-black {
  color: #000;
}

.embed-video-box {
  background-color: #242424;
  color: #fff;
  word-break: break-all;
  border-radius: 15px;
  flex-direction: column;
  height: 50px;
}

.copy-btn {
  border-radius: 10px;
  z-index: 99;
  cursor: pointer;
  width: 80px;
  height: 40px;
  padding: 10px 0px;
}

.generate-code-btn {
  border-radius: 10px;

  cursor: pointer;
  width: 100%;
  height: 40px;
  padding: 10px 0px;

}

.new-email-to-dev {
  border-radius: 10px;

  cursor: pointer;
  width: 100%;
  height: 40px;
  padding: 8px 0;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-border-width: var(--bs-border-width);
  display: inline-block;

  text-align: center;
  text-decoration: none;
  vertical-align: middle;

  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);

  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.video-preview-full video {
  min-height: 500px;
  width: 100%;
  object-fit: cover;
  height: calc(100vh - 210px);
}

.back-arrow-preview {
  position: absolute;
  top: 0px;
  left: 10px;
  z-index: 9;
}

/* video slider page */
.store-page-wrapper {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 566px;
  object-fit: cover;
  height: calc(100vh - 144px);
}

.store-page-wrapper-land {
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 250px;
  object-fit: cover;
  height: calc(100vh - 280px);
}


.store-page-wrapper .store-video {
  min-height: 500px;
  width: 100%;
  object-fit: contain;
  height: calc(100vh - 210px);
}


.store-page-wrapper .store-video.crop {
  min-height: 464px;
  width: 100%;
  object-fit: contain;
  height: calc(100vh - 246px);
}

.bottom-product-land {
  background: #fff;
  border-radius: 10px;
  padding: 2px 12px 2px 2px;
}

.bottom-product {
  background: #fff;
  border-radius: 10px;
  padding: 2px 12px 2px 2px;
  width: 290px;
}

.bottom-product-grid {
  display: grid;
  grid-template-columns: 75px 1fr;
  grid-gap: 10px;
  align-items: center;
}

.bottom-product-grid>img {
  width: 75px !important;
  height: 75px !important;
  border-radius: 10px;
  object-fit: contain;
}

.bottom-slider .carousel .slide {
  margin-right: 10px;
}

/*thumbnail and description do not fit issue resolved.*/
.productname {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  max-width: 23ch;
  -webkit-box-orient: vertical;
  font-size: 12px;
}

.bottom-slider .swiper-slide {
  width: auto;
}

.productprice {
  margin-left: 3px;
  font-weight: 700;
  font-size: 12px;
  align-items: center;
  display: flex;
  line-height: 1rem;
  display: flex;
  align-items: flex-start;
}

.buy-btn {
  background-color: #3b2c86;
  border: none;
  padding: 3px 12px;
  border-radius: 6px;
  font-size: 11px;
  display: inline-block;
  color: #fff;
  font-weight: 500;
}

.buy-btn:hover {
  background: rgb(34, 35, 35) !important;
  box-shadow: 0px 1px 5px 2px #3b2c86 !important;
}

.grid-buy {
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.select-variant-btn {
  color: #000;
  border: 1px solid #aaa;
  outline: none;
  padding: 3.5px 6px;
  font-size: 11px;
  background: no-repeat;
  font-weight: 600;
  white-space: nowrap;
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 4px
}

/* .grid-buy {
  grid-template-columns: auto auto auto;
  align-items: center;
  display: grid;
}

.select-variant-btn {
  color: #000;
  border: 1px solid #aaa;
  outline: none;
  padding: 3.5px 9px;
  font-size: 10px;
  background: no-repeat;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100px;
  text-overflow: ellipsis;
  margin-right: 4px;
} */

.land-overlay-bottom {
  width: 100%;
  position: relative;
  z-index: 9;
  text-align: left;
}


.overlay-bottom {
  position: relative;
  z-index: 9;
  text-align: left;
  bottom: 0px !important;
  height: auto;
}

.overlay-bottom .bottom-slider {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 80px;
}

.overlay-bottom .bottom-slider .owl-stage-outer {
  overflow: hidden;
  height: 80px;
}

.close-slide {
  position: absolute;
  top: -38px;
  right: 10px;
  border: none;
  background: #000;
  border-radius: 50%;
  padding: 0px;
  z-index: 9;
}

.close-slide img {
  height: 28px;
}

.btn-red-color.btn:focus {
  box-shadow: none;
}

.custom-header {
  padding: 15px 10px;
  position: relative;
  text-align: center;
}

.custom-header button.close {
  position: absolute;
  right: 10px;
  opacity: 0.9;
  top: 10px
}

.custom-header h4 {
  margin-bottom: 0px;
}

.theme-modal .modal-content {
  border-radius: 15px;
}

.theme-modal .form-control {
  min-height: 50px;
}

.theme-modal .form-control:focus {
  border: 1px solid #7b7b7b;
  box-shadow: none;
}

.store-page-wrapper>.w-100 {
  transform: translate3d(0, 0, 0);
}

.product-info {
  overflow: hidden;
}

.product-info p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-page-video {
  max-width: 600px;
  margin: 0 auto;
  width: 100%;

}

.product-page-video video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.btn-white-outline {
  color: #fff !important;
}

.btn-white-outline:hover,
.btn-white-outline:focus {
  color: #000 !important;
}



/*checkout payemnt*/
.form-checkout .form-control {
  border: 1px solid #bdbdbd;
  width: 100%;
  padding: 5px 14px;
  display: block;
  background: #1d1d1d;
  letter-spacing: 0.4px;
  font-weight: lighter;
  outline: 0;
  margin-bottom: 18px;
  border-radius: 10px;
  min-height: 54px;
  color: #fff;
}

.form-checkout .form-control:focus {
  border-color: #fff;
  box-shadow: none;
}

.form-checkout .custom-control-input:focus~.custom-control-label::before {
  box-shadow: none;
}

.form-checkout label {
  color: #fff;
  font-size: 15px;
  margin-bottom: 3px;
}

.address-chechbox.custom-checkbox .custom-control-label::before {
  border-radius: 50%;
  top: 2.5px;
  width: 20px;
  height: 20px;
}

.address-chechbox .custom-control-label::after {
  position: absolute;
  top: 4px;
  left: 2px;
}

.address-chechbox.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #f00;
}

.address-chechbox label {
  font-size: 12px;
}

.cartitem {
  border: 1px solid #bdbdbd;
  padding: 10px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 110px 1fr auto;
  align-items: center;
  grid-gap: 10px;
  color: #fff;
  width: 100%;
  max-width: 550px;
  margin: 0px auto 15px auto;
}

.cartitem>img {
  width: 110px;
  height: 110px;
  object-fit: cover;
  border-radius: 10px;
}

.cart-delete {
  background: transparent;
  border: none;
}

.label-size {
  background: #464646;
  padding: 4px 8px;
  margin-right: 10px;
  line-height: 16px;
  align-self: center;
}

.cartinfo-table {
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
}

.bggrey {
  background: #464646;
}

.cart-inner-scroll {
  height: calc(100vh - 300px);
  min-height: 270px;
  padding-right: 5px;
}

@media (max-width: 767px) {
  .btn-select-all {
    font-size: 14px;
  }

  .scrolling-products {
    height: calc(100vh - 348px);
  }

  .body-wrapper {
    padding: 15px 10px;
  }

  .dashboard-videos-grid {
    display: flex;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .dashboard-videos-grid .dashbaord-video .lower-dash {
    padding: 11px 5px;
  }

  .lower-dash p {
    font-size: 12px;
  }

  .dashboard-videos-grid .dashbaord-video .thumbimg {

  }

  .create-video-btn {
    max-width: 270px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 5px;
  }

  .product-head .headtitle-3 {
    font-size: 18px;
  }

  .headtitle-3 {
    font-size: 23px;
  }

  .product-column-grid {
    display: grid;
    grid-gap: 20px 15px;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  }

  .product-column-grid img {
    height: 140px;
  }

  .thumb-same {
    min-width: 100px;
    height: 100px;
    max-width: 100px;
    background: #272727;
    border: 1px solid white;
  }

  .add-product-box img {
    height: 25px;
  }

  .add-product-box {
    font-size: 12px;
  }

  .selected-video-area {
    height: 500px;
  }

  .input-addproduct {
    position: absolute;
  }


  .back-arrow-preview {
    top: 5px;
  }

  .header-fix {
    height: 60px !important;
  }

  .demo-pic {
    width: 36px !important;
    height: 36px !important;
  }

  .cartitem>img {
    width: 70px;
    height: 70px;
  }

  .cartitem {
    grid-template-columns: 70px 1fr auto;
    font-size: 14px;
  }

  .cartinfo-table {
    font-size: 14px;
  }

  .cart-inner-scroll {
    height: calc(100vh - 320px);
  }

}

video::-webkit-media-controls-fullscreen-button {
  display: none;
}



/*landscape video classes*/

/* .overlay-bottom-land {
  width: 100%;
  padding: 6px 0;
  height: 135px;
  position: relative;
} */


.store-page-wrapper-land .store-video-land {
  /* min-height: 300px; */
  width: 100%;
  object-fit: contain;
  margin-top: 35px;
  height: calc(100vh - 471px);
}

.store-page-wrapper-land .store-video.crop-land {
  min-height: 370px;
  width: 100%;
  object-fit: contain;
  height: calc(100vh - 400px);
}

.store-page-wrapper .store-video-crop {
  min-height: 464px;
  width: 100%;
  object-fit: contain;
  height: calc(100vh - 271px);
}

.custom-icons {
  gap: 6px;
  align-items: center;
}

.custom-icons p {
  margin: 0px !important;
  font-size: 14px;
  font-weight: 500;
}

.custom-icons .active-circle {
  height: 14px;
  border-radius: 50%;
  background: green;
  width: 14px;
  display: inline-block;
}

.custom-icons .archive-circle {
  height: 14px;
  border-radius: 50%;
  background: red;
  width: 14px;
  display: inline-block;
}

.custom-icons .draft-circle {
  height: 14px;
  border-radius: 50%;
  background: yellow;
  width: 14px;
  display: inline-block;
}

.custom-filters {
  width: 50% !important;
}

.custom-select-drop {
  gap: 10px !important;
}

.no-data {
  color: white;
  text-align: center;
  width: 100%;
  font-size: 20px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 40%;
}

@media screen and (max-width:768px) {
  .custom-filters {
    width: 100% !important;
  }
}

.custom-icon {
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  color: #fff;
  font-size: 24px;
}

.main-user-form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

}

.user-new-form {
  max-width: 300px;
}



.embed-video-box p {
  margin: 0 0 10px 0;
}

.user-new-video {
  margin-top: 10px;
}

.embed-code-new {
  min-height: 110px;
}

p.video-text {
  word-break: break-word;
  width: 100%;
}


@media only screen and (max-width:576px) {
  .embed-video-box {
    height: auto;
  }

  .dashboard-videos-grid {
    display: flex;

    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  p.video-text {
    font-size: 10px;
  }

  p.new-video-text {
    font-size: 10px;
  }

}

@media only screen and (max-width:420px) {
  .embed-video-box p {
    font-size: 14px;
  }


  .btn-preview-pge .btn {
    font-size: 12px !important;
    padding: 10px 3px;
  }

  .copy-text button {
    font-size: 14px;
  }



  .dashboard-videos-grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }
}

@media only screen and (max-width: 375px) {
  .store-page-wrapper .store-video {
    min-height: 420px;
  }


}

.inner-form {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  left: 0;
  margin: 20px;
}

button.copy-button {
  width: 105px;
  height: auto;
  background: #fd0807;
  color: #fff;
  border: 1px solid red;
  position: absolute;
  right: 0px;
  bottom: 0;
  border-radius: 0px 0.375rem 0.375rem 0px;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.labels-form {
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  padding: 12px 0px 0px 0px;
  margin: 2px 0;
}

.url-form {
  position: relative;
}

.get-button {
  width: 30%;
  height: 40px;
  background: #fd0807;
  color: #fff;
  border: 1px solid red;
  border-radius: 8px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.modal-textarea {
  height: 150px;
  resize: none;
}

.modal-textarea :focus,
.modal-textarea :focus-visible {
  border: none;
}

.main-link-form {
  border: 2px dashed white;
  min-height: 50px;
  position: relative;
}

.share-url {
  background: #272727;
  display: inline-block;
  color: #fff;
  font-size: 18px;
  position: absolute;
  top: -17px;
  left: 8px;
  padding: 2px 8px;
  margin: 0;
}

.main-get-form {
  border: 2px dashed white;
  min-height: 230px;
  position: relative;
  margin: 30px auto;
}

.url-form {
  position: relative;
  top: 0;
  left: 0;
  margin: 10px 20px;
}

.copy-link-button {
  width: auto;
  height: auto;
  background: #fd0807;
  color: #fff;
  border: 1px solid red;
  border-radius: 0.375rem 0.375rem;
  display: block;
  padding: 0.50rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.new-video-title {
  font-size: 14px !important;
  font-weight: 500;
}


.email-button {
  width: 100%;
  height: 40px;
  color: #fff;
  border-radius: 8px;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border: 1px solid white;
  display: flex;
  align-items: center;

}

.email-form-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

@media screen and (max-width:567px) {
  .email-button {
    font-size: 12px;
    padding: 0.3rem 0.3rem;
  }

  .get-button {
    font-size: 12px;
    padding: 0.3rem 0.1rem;

  }
}

/* landscape video */
.land-videowrapper-new {
  width: 100%;
  max-width: 800px;
  margin: 6rem auto;
}

.land-videocontainer-new {
  width: 100%;
  max-width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.land-videocontainer-new iframe,
.land-videocontainer-new video {
  width: 100%;
  max-width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;

}

@media screen and (max-height:600px) {
  .land-videowrapper-new {
    margin: 0 auto;
  }
}


/* portarit-video */

.videowrapper-new {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
}

.videocontainer-new {
  width: 100%;
  max-width: 100%;
  height: 0;
  padding-top: 178.25%;
  position: relative;
  margin: 0 auto;

}

.videocontainer-new iframe,
.videocontainer-new video {
  width: 100%;
  max-width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
}

@media screen and (max-height:600px) {
  .videowrapper-new {
    margin: 0 auto;
  }
}


/* @media screen and (max-width:330px) {
  .bottom-product-grid>img {
    width: 50px !important;
    height: 50px !important;
  }

  .buy-btn {
    padding: 4px 6px;
    font-size: 10px;
  }

  .select-variant-btn {
    font-size: 10px;
  }

  .bottom-product-grid {
    display: grid;
    grid-template-columns: 45px 1fr;

  }

  .productprice {
    margin-left: 3px;
    font-weight: 700;
    font-size: 10px;
    display: flex;
    align-items: flex-start;
  }

  .productname {
    max-width: 23ch;
    font-size: 10px;
  }

  .bottom-product {
    background: #fff;
    border-radius: 10px;
    padding: 2px 12px 2px 2px;
    width: 240px;
  }

} */
@media screen and (max-width:330px) {

  .buy-btn {
    padding: 4px 6px;
    font-size: 10px;
  }

  .select-variant-btn {
    font-size: 10px;
  }

  .bottom-product-grid {
    display: grid;
    grid-template-columns: 45px 1fr;

  }

  .productprice {
    margin-left: 3px;
    font-weight: 700;
    font-size: 10px;
    display: flex;
    align-items: flex-start;
  }

  .productname {
    max-width: 23ch;
    font-size: 10px;
  }

  .bottom-product {
    background: #fff;
    border-radius: 10px;
    padding: 2px 12px 2px 2px;
    width: 240px;
  }

}

/* @media screen and (max-width:330px) {
  .bottom-product-grid>img {
    width: 50px !important;
    height: 50px !important;
  }

  .buy-btn {
    padding: 4px 3px;
    font-size: 10px;
  }

  .select-variant-btn {
    font-size: 10px;
  }

  .bottom-product-grid {
    display: grid;
    grid-template-columns: 45px 1fr;

  }

  .bottom-product {
    background: #fff;
    border-radius: 10px;
    padding: 2px 12px 2px 2px;
    width: 240px;
  }

  .bottom-product-land {
    width: 240px;
  }
} */
.guide-text {
  color: #fff;
  margin-bottom: 5px;
  margin-right: 10px;
  font-weight: 300;
}

.guide-text-new {
  color: #fff;
  margin-bottom: 5px;
  font-weight: 300;
}

.info-main {
  width: 22px;
  height: 22px;
  border-radius: 11px;
  background-color: rgb(59, 44, 134);
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 5px;
  cursor: pointer;
}

.info-main:hover {
  background: rgb(34, 35, 35) !important;
  box-shadow: 0px 1px 5px 2px #3b2c86 !important;
}

.check-grid {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 10px;
  align-items: center;
  padding: 0 2px;
  margin-top: 5px;
}

.new-btn-label {
  position: relative;
  right: 30px;
  background: #fff;
  color: #3b3c86;
  border-radius: 100%;
  height: 28px;
  width: 28px;
  padding: 3px 0px;
}

.new-btn-label-2 {
  right: 30px;
  background: #fff;
  color: #3b3c86;
  border-radius: 100%;
  height: 28px;
  width: 28px;
  padding: 3px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-btn-label-2 p {
  margin-bottom: 0px;
}

.cart-item-buttons {
  margin-bottom: 0px !important;
  border: 1px solid white !important;
  padding: 0px 0px !important;
  border-radius: 3px !important;
  background: none !important;
  color: white;
  width: 23px;
  height: 22px;
}

.preview-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

}

.preview-content > iframe {



}
.text-white-muted {
  color: #9b9b9b;
}