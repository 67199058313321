html,
body {
  height: 100%;
  background-color: #272727;
}

.main-body {
  display: flex;
  width: 100%;
  height: 100%;
}

.sidebar {
  width: 300px;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #000;
  bottom: 0;
  height: 100%;
  z-index: 99;
}

.main-content {
  margin-left: 300px;
  width: calc(100% - 300px);
}

.side-navbar {
  list-style: none;
  padding-left: 0;
}

.sidelogo {
  padding-left: 30px;
  padding-right: 30px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  text-align: center;
  height: 80px;
  border-bottom: 1px solid rgb(249 250 246 / 20%);
}

.sidelogo .logo {
  max-width: 160px;
}

.side-navbar {
  margin-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
}

.side-navbar li a {
  display: inline-block;
  width: 100%;
  padding: 16px 16px;
  border-radius: 6px;
  font-weight: 500;
  color: #aaa;
  margin-bottom: 15px;
  text-decoration: none;
  display: flex;
  align-items: flex-end;
  position: relative;
  transition: 0.5s all;
}

.side-navbar li img {
  filter: grayscale(1);
  margin-right: 11px;
  position: relative;
  top: -3px;
  transition: 0.5s all;
}

.side-navbar li a:hover span {
  color: #fff;
  opacity: 0.8;
}

.side-navbar li a:hover img {
  filter: brightness(0) invert(0.8);
}

.side-navbar .active img {
  filter: none !important;
}

.side-navbar li.active a {
  background: #3635358f !important;
  color: #fff !important;
}

.text-white {
  color: #fff;
}

.text-white-small {
  color: #fff;
  font-size: 13px;
}

.header-fix {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 25px;
  background: #000;
  height: 80px;
  border-bottom: 1px solid #393939;
}

.demo-pic {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
}

@media(max-width: 991px) {
  .header-fix {
    justify-content: space-between;
  }

  .main-content {
    margin-left: 0px;
    width: 100%;
  }

  .sidebar {
    display: none;
  }

}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
}

.navbar-new {
  background: #000;
}

.box-wiget {
  background: #464343b3;
  padding: 30px;
  border-radius: 35px;
}

.profile-page {
  background: #000;
  min-height: 100vh;
  color: #fff;
  padding: 50px 0;
  font-family: 'Poppins', sans-serif;
}

.authlogo {
  width: 247px;
  margin-bottom: 40px;
}

.form-block .input-control {
  border: 1px solid #313030;
  width: 100%;
  padding: 5px 14px;
  display: block;
  background: #1d1d1d;
  letter-spacing: 0.4px;
  font-weight: lighter;
  outline: 0;
  margin-bottom: 15px;
  border-radius: 10px;
  min-height: 51px;
  color: #fff;
}

::placeholder {
  color: #fff;
  opacity: 0.9;
}

:-ms-input-placeholder {
  color: #fff;
  opacity: 0.9;
}

::-ms-input-placeholder {
  color: #fff;
  opacity: 0.9;
}

.imursif-link {
  text-decoration: none;
  color: #3b2c86;
  text-align: center;
}

.btn-save {
  color: #fff;
  border-radius: 13px;
  background: #3b2c86;
  padding: 10px;
  width: 100%;
  border: none;
  height: 50px;
  font-size: 17px;
}

p.headtitle {
  font-size: 18px;
  color: #c61c21;
  margin-bottom: 5px;
  font-weight: 600;
}

p.sub {
  color: #fff;
}

.app {
  margin-top: 60px;
}

.text-link {
  color: #fff;
  cursor: pointer;
  transition: 0.4s all;
}

.text-link:hover {
  color: #3b2c86;
  text-decoration: none;
}

.box-widget {
  padding-top: 30px;
}

.app img {
  width: 220px;
  padding-left: 30px;
}

.no-video h2 {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.no-video h2:after {
  content: '';
  background: #7b7b7b;
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0px;
  right: 0;
}

.box-widget {
  text-align: center;
}

.scroll-iframe body {
  overflow-y: hidden !important;
}

@media (max-width: 767px) {

  html,
  .top-data,
  body,
  #root {
    height: 100%;
    background-color: #272727 !important;
  }
}

@media(min-width:567px) {
  .no-video {
    height: calc(100vh - 240px);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    max-width: 450px;
    margin: 0 auto;
    text-align: left;
  }
}

@media(max-width:566px) {
  .no-video {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    max-width: 450px;
    margin: 35px auto;
    text-align: left;
  }

  .no-video h4 {
    font-size: 18px;
  }

  .no-video h3 {
    font-size: 22px;
  }

}