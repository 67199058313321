.customButton {

    cursor: pointer;
    width: 100%;
    height: 40px;
    padding: 8px 0;
    display: inline-block;

    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    background-color: #3b2c86;
    color: white;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    border: 1px solid #3b2c86;
    transition: all 0.5s ease-in-out;
}

.customButton:first-child {
    border-radius: 10px 0 0 10px;
}
.customButton:last-child {
    border-radius: 0 10px 10px 0;
}

.customButtonGroupActive{
    background-color: white;
    color: black;
    border: 1.5px solid white;
}

.customButtonGroup {
    display:flex;
    margin-bottom: 20px;
}